import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import AdminDashboard from "./pages/admin-dashboard";
import Events from "./pages/admin-dashboard/Events";
import Settings from "./pages/admin-dashboard/Settings";
import PrivateRoutes from "./components/routes/PrivateRoutes";
import { UserRoles } from "./utils/types";
import PublicRoutes from "./components/routes/PublicRoute";
import EventAttendance from "./pages/admin-dashboard/EventAttendace";
import EventsDelegates from "./pages/admin-dashboard/EventDelegates";
import RegisterForEvent from "./pages/user/RegisterForEvent";
import Delegates from "./pages/admin-dashboard/Delegates";
import SubAdmins from "./pages/admin-dashboard/SubAdmins";
import ExhibitorDelegates from "./pages/admin-dashboard/ExhibitorDelegates";
import UpdateSubAdmin from "./pages/admin-dashboard/UpdateSubAdmin";
import Attendees from "./pages/admin-dashboard/Attendees";
import GetQRCode from "./pages/user/GetQRCode";

const App = () => {
  return (
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <Notifications position="top-right" zIndex={2077} />
      <BrowserRouter>
        <Routes>
          <Route element={
            <PrivateRoutes roles={[UserRoles.ADMIN,
            UserRoles.SUB_ADMIN]}>
              <DashboardLayout />
            </PrivateRoutes>
          }>
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/dashboard/attendees" element={<Attendees/>}/> 
            <Route path="/dashboard/event-attendance/:eventId" element={<EventAttendance />} />
            <Route path="/dashboard/event-delegates/:eventId" element={<EventsDelegates />} />
            <Route path="/dashboard/settings" element={<Settings />} />
            <Route path="/dashboard/delegates" element={<Delegates/>}/>
          </Route>

          <Route element={
            <PrivateRoutes roles={[UserRoles.ADMIN]}>
              <DashboardLayout />
            </PrivateRoutes>
          }>
           
            <Route path="/dashboard/sub-admins" element={<SubAdmins/>}/>
            <Route path="/dashboard/sub-admins/:userId" element={<UpdateSubAdmin/>}/>
            <Route path="/dashboard/events" element={<Events />} />
          </Route>

          <Route element={
            <PrivateRoutes roles={[UserRoles.SUB_ADMIN]}>
              <DashboardLayout />
            </PrivateRoutes>
          }>
            <Route path="/dashboard/exhibitor-delegates" element={<ExhibitorDelegates/>}/>
          </Route>

          <Route path="/" element={
            <PublicRoutes>
              <Login />
            </PublicRoutes>
          } />
          <Route path="/login" element={
            <PublicRoutes>
              <Login />
            </PublicRoutes>
          } />

          <Route path="/events/:eventId" element={<RegisterForEvent />} />
          <Route path="/qrcode/:eventId" element={<GetQRCode/>}/>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </MantineProvider >
  );
}

export default App;