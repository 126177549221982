import { CSVLink } from "react-csv";

const useCSVExport = (
    data:any[],
    excelHeader:{label:string,key:string}[],
    filename="data.csv") => {

    const CSVBtn = () => (
    <CSVLink
    data={data}
    filename={filename}
    target="_blank"
    headers={excelHeader}
  >
      Export Data
  </CSVLink>)
    return {CSVBtn}
}
 
export default useCSVExport;