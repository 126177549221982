import PageWrapper from "../components/PageWrapper";
import { Text, TextInput } from "@mantine/core";
import { inputStyles, toast } from "../utils/helpers";
import { IoMailOutline } from "react-icons/io5";
import PasswordInput from "../components/PasswordInput";
import useButton from "../hooks/useButton";
import { BiLockAlt } from "react-icons/bi";
import { login } from "../services/userService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action_Type, useUserContext } from "../context/UserContext";

const Login = () => {
    const { AppButton, setLoading } = useButton()
    const navigate = useNavigate()

    const [user, setUser] = useState({
        password: "",
        email: ""
    })
    const { userDispatch } = useUserContext()
    const handleInputChange = ({ target: { name, value } }: { target: { name: string, value: string } }) => setUser({ ...user, [name]: value })

    const redirectUrl = new URLSearchParams(window.location.search).get("redirect")
    const handleSubmit = (e: any) => {
        setLoading(true)
        e.preventDefault()
        login(user).then(res => {
            setLoading(false)
            toast(res.data.message).success()
            setUser({
                email: "",
                password: "",
            })
            if (userDispatch)
                userDispatch({
                    type: Action_Type.USER_PROFILE,
                    payload: res.data.data,
                })
            navigate(redirectUrl?redirectUrl:"/dashboard")
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }

    return (
        <PageWrapper>
            <Text
                color="dark"
                align="center"
                my={2}
                className='font-bold text-lg font-1'
            >
                Login to your account
            </Text>
            <form onSubmit={handleSubmit}>
                <TextInput
                    value={user.email}
                    onChange={handleInputChange}
                    mt={10}
                    name='email'
                    size='md'
                    // className='!bg-color-4 focus:!border-color-1 !border-none'
                    styles={{
                        input: inputStyles
                    }}
                    placeholder='Email Address'
                    icon={<IoMailOutline className='text-lg' />} />
                <PasswordInput
                    name={"password"}
                    value={user.password}
                    handleChange={handleInputChange}
                    icon={<BiLockAlt />}
                    placeholder="Password"
                />


                <AppButton title="Login" />
            </form>
        </PageWrapper>
    );
}

export default Login;