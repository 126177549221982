import { Card, Text } from "@mantine/core";
import { Link} from "react-router-dom";


interface AppCardProps {
    title: string,
    action: string,
    actionPath: string,
    totalNumber: number,
}

const AppCard: React.FC<AppCardProps> = ({ title, action, totalNumber, actionPath }) => {
    return (
        <Card
            className={`rounded-xl bg-white py-6 px-7 sm:h-[100px] h-[170px] font-3 flex flex-col justify-between`}>
            <Text>{title}</Text>
            <h1>{totalNumber}</h1>
            <Link to={actionPath} className="cursor-pointer underline">{action}</Link>
        </Card>
    );
}

export default AppCard;