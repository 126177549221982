import { TextInput, Text, Skeleton, Image } from "@mantine/core";
import { MdOutlineAlternateEmail } from "react-icons/md"
import { imageUrl, inputStyles, toast } from "../../utils/helpers";
import { useEffect, useState } from "react";
import { generateEventQRCode, getEvent } from "../../services/userService";
import useButton from "../../hooks/useButton";
import { useNavigate, useParams, Link } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper";
//@ts-ignore
import { saveAs } from "file-saver";

const GetQRCode = () => {
    const [userEmail, setUserEmail] = useState("")
    const [image, setImage] = useState("")

    const { AppButton, setLoading } = useButton()
    const [event, setEvent] = useState({
        _id: "",
        name: ""
    })
    const { eventId } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!eventId) return;
        setIsLoading(true)
        getEvent(eventId).then(res => {
            setIsLoading(false)
            setEvent(res.data?.data)
        }).catch(err => {
            setIsLoading(false)
            toast(err?.response?.data?.message).error()
            navigate("*")
        })
    }, [eventId])

    const triggerDownloadPipe = (blob: Blob,filename = "filename") => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${filename}-${new Date().toISOString()}.png`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const handleInviteUser = (e: any) => {
        e.preventDefault()
        setLoading(true)
        generateEventQRCode(event?._id, userEmail)
            .then(res => {
                toast("QR Code Generated Successfully!").success()
                // const blob = new Blob([res.data], { type: "image/png" })
                // saveAs(blob, `${userEmail}-${event?.name}-qrcode.png`)
                setImage(res.data?.data)
                setLoading(false)
                setUserEmail("")
            }).catch(err => {
                toast(err?.response?.data?.message).error()
                setLoading(false)
                setImage("")
            })
    }
    return (
        <>
            <PageWrapper
            //  height={'min-h-[500px]'}
            >
                <Skeleton visible={isLoading}>
                    <Text
                        color="dark"
                        align="center"
                        my={2}
                        className='font-bold text-lg font-1'
                    >
                        Check QR Code For {event.name}
                    </Text>
                    <form onSubmit={handleInviteUser}>
                        <TextInput
                            icon={<MdOutlineAlternateEmail />}
                            onChange={({ target }) => setUserEmail(target.value)}
                            value={userEmail}
                            required
                            className="font-1 w-full"
                            size="md"
                            placeholder="Email"
                            mt={10}
                            sx={{ input: inputStyles }}
                        />
                        <AppButton title="Check QR Code" />
                        {image && <Image src={image} className="w-full my-2" />}
                        <div className="my-1">
                            <Text size={'sm'}>You haven't been invited click <Link
                                className="text-color-1"
                                to={`/events/${event?._id}`}>here</Link></Text>
                        </div>
                    </form>
                </Skeleton>
            </PageWrapper>
        </>
    );
}

export default GetQRCode;