import { Flex, TextInput } from "@mantine/core";
import useButton from "../../../../hooks/useButton";
import { inputStyles, toast } from "../../../../utils/helpers";
import PasswordInput from "../../../PasswordInput";
import { createSubAdmin } from "../../../../services/userService";
import { useState } from "react";
//@ts-ignore
import { saveAs } from 'file-saver';

const CreateSubAdmin = () => {

    const { AppButton, setLoading, loading } = useButton()

    const [info, setInfo] = useState({
        name: "",
        email: "",
        password: ""
    })

    const handleInputChnage = ({ target: { name, value } }: { target: { name: string, value: string } }) => setInfo({ ...info, [name]: value })

    const handleCreation = (e: any) => {
        e.preventDefault()
        if (!info.email || !info.name || !info.password) {
            toast("Fill all fields!").error()
            return;
        }
        setLoading(true)
        createSubAdmin(info).then(res => {
            toast("User Created Successfully!").success()
            setLoading(false)
            const { _id, name, email } = res.data?.data;
            const data = new Blob([`name:${name}\nemail:${email}\npassword:${info.password}`], { type: "text/plain;charset=utf-8" })
            saveAs(data, `${name}-${_id}-staff-details.txt`)
            setInfo({
                name: "",
                email: "",
                password: ""
            })
        }).catch(err => {
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }


    return (
        <Flex className="w-full md:w-[60%] flex flex-col md:mx-auto lg:mx-0 justify-around mt-[30px]">
            <form onSubmit={handleCreation}>
                <TextInput
                    disabled={loading}
                    required
                    name="name"
                    value={info.name}
                    onChange={handleInputChnage}
                    className="font-1s w-full"
                    size="md"
                    placeholder="Name"
                    mt={10}
                    sx={{ input: inputStyles }}
                />
                <TextInput
                    value={info.email}
                    required
                    disabled={loading}
                    name="email"
                    onChange={handleInputChnage}
                    className="font-Poppins w-full"
                    size="md"
                    placeholder="Email"
                    mt={10}
                    sx={{ input: inputStyles }}
                />
                <PasswordInput
                    value={info.password}
                    disabled={loading}
                    handleChange={handleInputChnage}
                    name="password"
                    placeholder="Password" />
                <AppButton title="Proceed" />
            </form>
        </Flex>
    );
}

export default CreateSubAdmin;