/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button, Card,
    Divider,
    Flex, ScrollArea,
    Skeleton, Table, Text, TextInput,
    Tooltip
} from "@mantine/core";
import { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { useParams } from "react-router-dom";
import BackBtn from "../../components/BackBtn";
import useButton from "../../hooks/useButton";
import useCSVExport from "../../hooks/useCSVExport";
import usePagination from "../../hooks/usePagination";
import { getDelegates, inviteUsers } from "../../services/userService";
import { delData, retrieveData, storeData, toast } from "../../utils/helpers";
import kingsChatWebSdk from 'kingschat-web-sdk';
//@ts-ignore
import _debounce from 'lodash.debounce';
import { AiOutlineSearch } from "react-icons/ai";
import axios from "../../services/axiosInstance";

const Row = ({ item }: any) => {
    const { AppButton, setLoading } = useButton()
    const [sendingMessage, setSendingMessage] = useState(false)

    const handleInviteUsers = () => {
        if (!item?.event?._id) return;
        setLoading(true)
        inviteUsers(item?.event?._id, [{ name: item?.name, email: item?.email }], false, true).then(res => {
            toast(res.data?.message).success()
            setLoading(false)
        }).catch((err: any) => {
            toast((err?.response?.data?.message) as string).error()
            setLoading(false)
        })
    }

    async function sendMessageViaKingchat(kingschatUserId: string, qrcode: string) {
        try {
            setSendingMessage(true)
            const token = retrieveData()
            let accessToken = token;
            let qrcodeUrl = qrcode;

            if (!qrcodeUrl) {
                const res = await axios.get(`/admin/generate-qrcode/${item._id}`)
                if (!res.data?.qrcode)
                    throw new Error(`Couldn't generate QRCode for ${item?.name}!`)

                qrcodeUrl = res?.data?.qrcode;
            }

            if (!token) {
                const authenticationTokenResponse = await kingsChatWebSdk.login({
                    clientId: "e2929353-16f6-49ca-a1b9-92865720a587",
                    scopes: ["send_chat_message"]
                })

                accessToken = authenticationTokenResponse.accessToken;
                storeData(accessToken)
            }
            if (!accessToken) throw new Error("Login failed!")
            await kingsChatWebSdk.sendMessage({
                accessToken,
                message: `Your Access Badge to ${item?.event?.name || "--"}.\n
                Dear ${item?.name},\n
                This event is on ${item?.event?.date?.toString() || "--"}.\n
                Kindly click on the link below to view your QR Code and have it displayed for scanning while at the Angel Court Exhibitions & Seminars.\n
                ${qrcodeUrl}\n
                Best regards,\n
                Delegate Capture Pro Team\n
                LWPM/ROR Exhibitions & Seminars`,
                userIdentifier: kingschatUserId
            })
            toast("Successfully sent message").success()
            setSendingMessage(false)
        } catch (err: any) {
            delData()
            setSendingMessage(false)
            toast((err?.message || err?.response?.data?.message || `Couldn't send QRCode to ${item?.name} via kingschat!`) as string).error()
        }
    }

    return (
        <tr key={item?._id}>
            <td>
                <Flex direction={"column"}>
                    <Text fw={600} size={"sm"}>{item?.name}</Text>
                    <Text c={"dimmed"} size={"xs"}>{item?.email}</Text>
                </Flex>
            </td>
            <td>{item?.event?.name}</td>
            <td>
                <Flex direction={"column"}>
                    <Text fw={600} size={'sm'}>{item?.invitedBy?.name || "--"}</Text>
                    <Text c={"dimmed"} size={"xs"}>
                        {new Date(item?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: "2-digit", year: "numeric" })}
                    </Text>
                </Flex>
            </td>
            <td>
                <Flex direction={"column"}>
                    {item?.kingschatUserId && <>
                        <Button
                            loading={sendingMessage}
                            onClick={() => sendMessageViaKingchat(item?.kingschatUserId as string, item?.qrcode as string)}
                            className="bg-color-1 hover:bg-color-1">Via Kingschat</Button>
                        <Divider label="or" orientation="horizontal"
                            labelPosition="center" />
                    </>}
                    <AppButton
                        title="Via Email"
                        handleClick={handleInviteUsers} />
                </Flex>
            </td>
        </tr>
    )
}

const Delegates = () => {
    const [delegates, setDelegates] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const { eventId } = useParams()
    const [loading, setIsLoading] = useState(false)
    const [entry, setEntry] = useState("")

    useEffect(() => {
        setIsLoading(true)
        getDelegates().then(res => {
            // Directly set delegates in descending order
            setDelegates(res.data.data.reverse());
            setIsLoading(false);
        }).catch((err: any) => {
            setIsLoading(false);
            toast((err?.response?.data?.message || "Error loading delegates") as string).error();
        });
    }, [])

    const { PaginationBtn, data } = usePagination(!entry ? delegates : searchResults)
    const [sendingKingchatMessage, setSendingKingchatMessage] = useState(false)

    const excelHeader = [
        { label: "Event ID", key: "event._id" },
        { label: "Event Name", key: "event.name" },
        { label: "Name", key: "name" },
        { label: "Country", key: "country" },
        { label: "Email", key: "email" },
        { label: "Invited By", key: "invitedBy.name" },
        { label: "Time", key: "createdAt" },
    ]
    const { CSVBtn } = useCSVExport(!entry ? delegates : searchResults, excelHeader)

    const handleSearch = _debounce(async (query: string) => {
        if (!query) return;
        try {
            const response = await getDelegates(query)
            if (response?.data?.data.length > 0) {
                setSearchResults(response.data?.data)
            }
            if (response?.data?.data.length === 0) {
                setSearchResults([])
            }
        } catch (error: any) {
            console.log(JSON.stringify(error.response, null, 5))
        }
    }, 300)


    async function sendBulkMessageViaKingchat() {
        try {
            setSendingKingchatMessage(true)
            const token = retrieveData()
            let accessToken = token as string;

            const kingschatDelegates = delegates?.filter((d: any) => !!d?.kingschatUserId) || []
            const messagesSent = new Map()
            messagesSent.clear()
            if (!kingschatDelegates.length) return toast("No Delegates registered with Kingchat!").error()

            if (!token) {
                const authenticationTokenResponse = await kingsChatWebSdk.login({
                    clientId: "e2929353-16f6-49ca-a1b9-92865720a587",
                    scopes: ["send_chat_message"]
                })

                accessToken = authenticationTokenResponse.accessToken;
                storeData(accessToken)
            }
            if (!accessToken) throw new Error("Login failed!")

            await Promise.all(kingschatDelegates.map(async (delegate: any) => {
                let qrcodeUrl = delegate?.qrcode;

                if (!qrcodeUrl) {
                    const res = await axios.get(`/admin/generate-qrcode/${delegate._id}`)
                    if (!res.data?.qrcode)
                        throw new Error(`Couldn't generate QRCode for ${delegate?.name}!`)

                    qrcodeUrl = res?.data?.qrcode;
                }

                await kingsChatWebSdk.sendMessage({
                    accessToken,
                    message: `Your Access Badge to ${delegate?.event?.name || "--"}.\n
                Dear ${delegate?.name},\n
                This event is on ${delegate?.event?.date?.toString() || "--"}.\n
                Kindly click on the link below to view your QR Code and have it displayed for scanning while at the Angel Court Exhibitions & Seminars.\n
                ${qrcodeUrl}\n
                Best regards,\n
                Delegate Capture Pro Team\n
                LWPM/ROR Exhibitions & Seminars`,
                    userIdentifier: delegate?.kingschatUserId
                })
                messagesSent.set(delegate?.kingschatUserId, qrcodeUrl)
            }))
            setSendingKingchatMessage(false)
            return toast("Successfully sent message").success()
        } catch (err: any) {
            delData()
            setSendingKingchatMessage(false)
            toast((err?.message || err?.response?.data?.message) as string).error()
        }
    }


    return (
        <>
            <Flex className="flex flex-row items-center justify-between">
                <div
                    className="flex flex-col w-full"
                >
                    <BackBtn to="/dashboard" />
                    <h1 className="my-1 font-3">Delegates</h1>
                </div>
                {(delegates.length > 0) || (searchResults.length > 0) ? <Button
                    className="bg-color-1"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button> : null}
            </Flex>
            <Card
                mih={500}
                padding={20}>
                <Skeleton visible={loading}>
                    <ScrollArea>
                        <TextInput
                            onChange={({ target }) => {
                                setEntry(target.value)
                                handleSearch(target.value)
                            }}
                            icon={<AiOutlineSearch />}
                            placeholder="Search delegates" className="h-[70px]" />
                        <Flex justify={"right"}>
                            <Tooltip
                                position="top"
                                withArrow
                                label={"This applies to all delegates"}>
                                <Button
                                    size="xs"
                                    className="bg-color-1 hover:bg-color-1"
                                    onClick={sendBulkMessageViaKingchat}
                                    loading={sendingKingchatMessage}>Send QRCode Via Kingschat</Button>
                            </Tooltip>
                        </Flex>
                        <Table verticalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Name/Email</th>
                                    <th>Event Name</th>
                                    <th>Invited By/Date</th>
                                    <th>Resend QrCode</th>
                                </tr>
                            </thead>

                            <tbody>{data()?.map(item => <Row item={item} />)}</tbody>
                        </Table>
                        {delegates.length === 0 &&
                            <Flex className="items-center justify-center mt-5">
                                <Text>No data found</Text>
                            </Flex>
                        }
                    </ScrollArea>
                </Skeleton>
                <PaginationBtn />

            </Card>
        </>
    );
}

export default Delegates;
