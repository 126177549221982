/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Flex, ScrollArea, Skeleton, Table, Text, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { eventAttendance, } from "../../services/userService";
import { toast } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import usePagination from "../../hooks/usePagination";
import { CiExport } from "react-icons/ci";
import useCSVExport from "../../hooks/useCSVExport";
import BackBtn from "../../components/BackBtn";
//@ts-ignore
import _debounce from 'lodash.debounce';
import { AiOutlineSearch } from "react-icons/ai";
import { useUserContext } from "../../context/UserContext";
import { UserRoles } from "../../utils/types";

const Row = ({ item }: any) => {
    return (
        <tr key={item?._id}>
            <td>{item?.name}</td>
            <td>{item?.email}</td>
            <td>{item?.event?.name}</td>
            <td>{item?.checkedInBy?.name}</td>
            <td>{new Date(item?.createdAt)
                .toLocaleTimeString('en-GB',
                    { day: '2-digit', month: "2-digit", year: "numeric" })
                .split(",")[0].trim()
            }</td>
            {/* <td>{evt?.name}</td> */}
        </tr>
    )
}

const EventAttendance = () => {
    const [attendance, setAttendance] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const { eventId } = useParams()
    const [loading, setIsLoading] = useState(false)
    const [entry, setEntry] = useState("")
    const { user } = useUserContext()

    useEffect(() => {
        if (!eventId) return;
        setIsLoading(true)
        eventAttendance(eventId).then(res => {
            setAttendance(res.data.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [eventId])

    const { PaginationBtn, data } = usePagination(attendance)

    const excelHeader = [
        { label: "Event ID", key: "event._id" },
        { label: "Event Name", key: "event.name" },
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Phone", key: "delegate.phone" },
        { label: "Country", key: "delegate.country" },
        { label: "Zonal", key: "delegate.zonal" },
        { label: "Scanned By", key: "event.checkedInBy.name" },
        { label: "Time", key: "createdAt" },
    ]
    const { CSVBtn } = useCSVExport(!entry ? attendance : searchResults, excelHeader)

    const handleSearch = _debounce(async (query: string) => {
        if (!query || !eventId) return;
        try {
            const response = await eventAttendance(eventId, query)
            if (response?.data?.data.length > 0) {
                setSearchResults(response.data?.data)
            }
            if (response?.data?.data.length === 0) {
                setSearchResults([])
            }
        } catch (error: any) {
            console.log(JSON.stringify(error.response, null, 5))
        }
    }, 300)
    return (
        <>
            <Flex className="flex flex-row items-center justify-between">

                <div
                    className="flex flex-col w-full"
                >
                    <BackBtn to={user?.role === UserRoles.ADMIN ? "/dashboard/events" : "/dashboard"} />
                    <h1 className="my-1 font-3">Attendances</h1>
                </div>
                {(attendance.length > 0) || (searchResults.length > 0) ? <Button
                    className="bg-color-1"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button> : null}
            </Flex>
            <Card
                mih={500}
                padding={20}>
                <Skeleton visible={loading}>
                    <ScrollArea>
                        {attendance.length > 0 || searchResults.length > 0 ? <TextInput
                            onChange={({ target }) => {
                                setEntry(target.value)
                                handleSearch(target.value)
                            }}
                            icon={<AiOutlineSearch />}
                            placeholder="Search Attendees" className="h-[70px]" /> : null}
                        <Table verticalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Event Name</th>
                                    <th>Scanned By</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody>{data()?.map(item => <Row item={item} />)}</tbody>
                        </Table>
                        {attendance.length === 0 &&
                            <Flex className="items-center justify-center mt-5">
                                <Text>No data found</Text>
                            </Flex>
                        }
                    </ScrollArea>
                </Skeleton>
                <PaginationBtn />

            </Card>
        </>
    );
}

export default EventAttendance;