import { Button } from '@mantine/core';
import { ReactNode, useState } from 'react';

const useButton = () => {
    const [loading, setLoading] = useState(false)


    function AppButton({ title,
        handleClick, leftIcon, styles="hover:bg-red-500 bg-color-3" }:
        { title: string, handleClick?: () => void, leftIcon?: ReactNode, styles?: string }) {
        return (
            <Button
                leftIcon={leftIcon}
                type='submit'
                loading={loading}
                loaderPosition='left'
                onClick={handleClick}
                className={`
        ${styles}
        transition 
        w-full
        duration-300
         ease-in-out 
         hover:text-white
         font-Poppins`}
                mt={10}
            >{title}</Button>
        );
    }


    return { loading, setLoading, AppButton }
}

export default useButton;