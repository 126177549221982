import React, { ReactNode, useState } from "react";
import { TextInput, Text } from "@mantine/core";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { inputStyles } from "../utils/helpers";

interface PasswordInputProps {
    value?: string;
    handleChange?: React.ChangeEventHandler<HTMLInputElement>;
    handleBlur?: React.FocusEventHandler<HTMLInputElement>;
    error?: string;
    touched?: boolean;
    disabled?: boolean;
    inputRef?: any
    placeholder?: string;
    icon?: ReactNode;
    name?: string;
    required?: boolean
}


const PasswordInput: React.FC<PasswordInputProps> =
    ({ value, handleBlur, handleChange, touched, error,
        disabled, inputRef, placeholder = "Password", icon,
        name, required = true }) => {
        const [hidePassword, sethidePassword] = useState(true);
        return (
            <>
                <TextInput
                    name={name}
                    icon={icon}
                    ref={inputRef}
                    disabled={disabled}
                    required={required}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={hidePassword ? "password" : "text"}
                    rightSection={hidePassword ? <AiOutlineEyeInvisible onClick={() => sethidePassword(!hidePassword)} /> :
                        <AiOutlineEye onClick={() => sethidePassword(!hidePassword)} />}
                    className="font-Poppins w-full"
                    size="md"
                    placeholder={placeholder}
                    mt={10}
                    sx={{ input: inputStyles }}
                />
            </>

        );
    }

export default PasswordInput;