import axios from "./axiosInstance";

type User = {
    name?: string;
    email: string
    password: string
    country?: string
}



//all users access
export const login = async (user: User) => await axios.post('/users/login', user)

export const getUserProfile = async () => await axios.get("/users/profile");

export const updateUserProfle = async (user: any) => await axios.patch("/users/profile", user);

export const updatePassword = async (data: any) => await axios.patch("/users/update-password", data)
//admin only access
export const createEvent = async (data: any) => await axios.post("/admin/events", data)

export const createSubAdmin = async (data: any) => axios.post("/admin/sub-admin", data)

export const getEvents = async (eventName?: string) => await axios.get(!eventName ? `/admin/events` : `/admin/events?name=${eventName}`)

export const inviteUsers = async (eventId: string, users: any, createDelegate: boolean, privateroute?: boolean) => await axios.post(
    !privateroute ?
        `/admin/invite-users/${eventId}` : `/admin/invite-users-private/${eventId}`, { users, createDelegate })

export const eventAttendance = async (eventId: string,entry?: string) =>
 await axios.get(!entry?`/admin/event-attendance/${eventId}`:`/admin/event-attendance/${eventId}?entry=${entry}`)

export const eventDelegates = async (eventId: string, entry?: string) => await axios.get(
    !entry ?
        `/admin/event-delegates/${eventId}` : `/admin/event-delegates/${eventId}?entry=${entry}`)

export const getEvent = async (eventId: string) => await axios.get(`/admin/event/${eventId}`)

export const deleteEvent = async (eventId: string) => await axios.delete(`/admin/event/${eventId}`)

export const getDelegates = async (entry?:string) => await axios.get(!entry?`/admin/delegates`:`/admin/delegates?entry=${entry}`)

export const getAttendees = async (entry?:string) => await axios.get(!entry?`/admin/attendees`:`/admin/attendees?entry=${entry}`)

export const getSubAdmins = async () => await axios.get(`/admin/sub-admins`)

export const getExhibitorDelegates = async () => await axios.get(`/admin/exhibitor-delegates`)

export const getSubAdmin = async (userId:string) => await axios.get(`/admin/sub-admin/${userId}`)

//@ts-ignore
export const updateSubAdmin = async (userId:string, data) => await axios.put(`/admin/sub-admin/${userId}`, data)

// export const searchEvents = async (evtName:string) => await axios.get(`/admin/events?name=${evtName}`)

export const generateEventQRCode = async (eventId:string,email:string) => await axios.post(`/users/qrcode/${eventId}`, {email})