import { Card, Flex, Skeleton, TextInput, Text } from "@mantine/core";
import { useEffect, useState } from "react";
//@ts-ignore
import { saveAs } from 'file-saver';
import { inputStyles, toast } from "../../utils/helpers";
import useButton from "../../hooks/useButton";
import { createSubAdmin, getSubAdmin, updateSubAdmin } from "../../services/userService";
import PasswordInput from "../../components/PasswordInput";
import { useParams } from "react-router-dom";
import BackBtn from "../../components/BackBtn";

const UpdateSubAdmin = () => {
    const [info, setInfo] = useState({
        name: "",
        email: "",
        password: ""
    })

    const { userId } = useParams()

    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        if (!userId) return;
        setIsLoading(true)
        getSubAdmin(userId).then(res => {
            setInfo({
                ...res.data?.data
            })
            setIsLoading(false)
        }).catch(err => {
            toast(err?.response?.data?.message).error()
            setIsLoading(false)
        })
    }, [userId])


    const { AppButton, setLoading, loading } = useButton()



    const handleInputChnage = ({ target: { name, value } }: { target: { name: string, value: string } }) => setInfo({ ...info, [name]: value })

    const handleUpdate = (e: any) => {
        e.preventDefault()
        if (!info.email || !info.name) {
            toast("Fill all fields!").error()
            return;
        }
        setLoading(true)
        const data = { email: info.email, name: info.name }
        if (info.password) {
            //@ts-ignore
            data["password"] = info.password
        }
        updateSubAdmin(userId as string, data).then(res => {
            toast(res.data?.message).success()
            setLoading(false)
            const { _id, name, email } = res.data?.data;
            const data = new Blob([`name:${name}\nemail:${email}\npassword:${info.password ?? ""}`], { type: "text/plain;charset=utf-8" })
            saveAs(data, `${name}-${_id}-details.txt`)
            // setInfo({
            //     name: "",
            //     email: "",
            //     password: ""
            // })
        }).catch(err => {
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }


    return (
        <>
            <BackBtn to="/dashboard/sub-admins"/>
            <h1 className="my-2">Update Exhibitor</h1>
            <Card
                padding={20}
                mih={300}>
                <Flex className="w-full md:w-[60%] flex flex-col md:mx-auto lg:mx-0 justify-around mt-[30px]">
                    <Skeleton visible={isLoading}>
                        <form onSubmit={handleUpdate}>
                            <TextInput
                                disabled={loading}
                                required
                                name="name"
                                value={info.name}
                                onChange={handleInputChnage}
                                className="font-1s w-full"
                                size="md"
                                placeholder="Name"
                                mt={10}
                                sx={{ input: inputStyles }}
                            />
                            <TextInput
                                value={info.email}
                                required
                                disabled={loading}
                                name="email"
                                onChange={handleInputChnage}
                                className="font-Poppins w-full"
                                size="md"
                                placeholder="Email"
                                mt={10}
                                sx={{ input: inputStyles }}
                            />
                            <PasswordInput
                                required={false}
                                value={info.password}
                                disabled={loading}
                                handleChange={handleInputChnage}
                                name="password"
                                placeholder="Password" />
                            <AppButton title="Proceed" />
                        </form>
                    </Skeleton>

                </Flex>
            </Card>

        </>

    );
}

export default UpdateSubAdmin;