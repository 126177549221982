/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button, Card, Flex, ScrollArea,
    Skeleton, Table, Text, TextInput
} from "@mantine/core";
import { useEffect, useState } from "react";
import { inviteUsers, getSubAdmins } from "../../services/userService";
import { toast } from "../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import usePagination from "../../hooks/usePagination";
import { CiExport } from "react-icons/ci";
import useCSVExport from "../../hooks/useCSVExport";
import useButton from "../../hooks/useButton";
import BackBtn from "../../components/BackBtn";
//@ts-ignore
import _debounce from 'lodash.debounce';
import { AiOutlineSearch } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi"

const Row = ({ item }: any) => {
    const { AppButton, setLoading } = useButton()
    const navigate = useNavigate()

    const handleInviteUsers = () => {
        if (!item?.event?._id) return;
        setLoading(true)
        inviteUsers(item?.event?._id, [{ name: item?.name, email: item?.email }], false, true).then(res => {
            toast(res.data?.message).success()
            setLoading(false)
        }).catch(err => {
            toast(err?.response?.data?.message).error()
            setLoading(false)
        })
    }

    return (
        <tr key={item?._id}>
            <td>{item?.name}</td>
            <td>{item?.email}</td>
            <td>
                <Button
                    onClick={() => navigate(`/dashboard/sub-admins/${item?._id}`)}
                    className="hover:bg-[#f8f9fa] bg-[#f8f9fa] text-black hover:text-black"
                    leftIcon={<BiEditAlt />}>Edit</Button>
            </td>
        </tr>
    )
}

const SubAdmins = () => {
    const [subAdmins, setAdmins] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const { eventId } = useParams()
    const [loading, setIsLoading] = useState(false)
    const [entry, setEntry] = useState("")

    useEffect(() => {
        setIsLoading(true)
        getSubAdmins().then(res => {
            setAdmins(res.data.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [])

    const { PaginationBtn, data } = usePagination(!entry ? subAdmins : searchResults)

    const excelHeader = [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
    ]
    const { CSVBtn } = useCSVExport(!entry ? subAdmins : searchResults, excelHeader)

    // Search function to filter sub-admins based on input
    const handleSearch = _debounce(async (query: string) => {
        if (!query) {
            setSearchResults([])
            setEntry("")
            return;
        }

        setEntry(query)
        const filtered = subAdmins.filter((admin: any) =>
            admin.name.toLowerCase().includes(query.toLowerCase()) ||
            admin.email.toLowerCase().includes(query.toLowerCase())
        )
        setSearchResults(filtered)
    }, 300)

    return (
        <>
            <Flex className="flex flex-row items-center justify-between">
                <div
                    className="flex flex-col w-full"
                >
                    <BackBtn to="/dashboard" />
                    <h1 className="my-1 font-3">Exhibitors</h1>
                </div>
                {(subAdmins.length > 0) || (searchResults.length > 0) ? <Button
                    className="bg-color-1"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button> : null}
            </Flex>
            <Card
                mih={500}
                padding={20}>
                <Skeleton visible={loading}>
                    <ScrollArea>
                        <TextInput
                            onChange={({ target }) => handleSearch(target.value)}
                            icon={<AiOutlineSearch />}
                            placeholder="Search exhibitors" 
                            className="h-[70px]"
                        />
                        <Table verticalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>{data()?.map(item => <Row item={item} />)}</tbody>
                        </Table>
                        {subAdmins.length === 0 &&
                            <Flex className="items-center justify-center mt-5">
                                <Text>No data found</Text>
                            </Flex>
                        }
                    </ScrollArea>
                </Skeleton>
                <PaginationBtn />

            </Card>
        </>
    );
}

export default SubAdmins;
