import { Pagination } from "@mantine/core";
import { useState } from "react";


const usePagination = (data: any[]) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const paginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.reverse().slice(startIndex, endIndex);
    };


    const PaginationBtn = () => (
        <div className="w-full flex items-center my-5">
            <Pagination
                className="mx-auto"
                size={'lg'}
                value={currentPage}
                total={Math.ceil(data.length / itemsPerPage)}
                onChange={setCurrentPage}
            />
        </div>
    )


    return { PaginationBtn, data: paginatedData };
}

export default usePagination;