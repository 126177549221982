/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button, Card, Divider, Flex, ScrollArea,
    Skeleton, Table, Text, TextInput,
    Tooltip
} from "@mantine/core";
import kingsChatWebSdk from 'kingschat-web-sdk';
import { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { useParams } from "react-router-dom";
import BackBtn from "../../components/BackBtn";
import useButton from "../../hooks/useButton";
import useCSVExport from "../../hooks/useCSVExport";
import usePagination from "../../hooks/usePagination";
import { eventDelegates, inviteUsers, } from "../../services/userService";
import { delData, retrieveData, storeData, toast } from "../../utils/helpers";
//@ts-ignore
import _debounce from 'lodash.debounce';
import { AiOutlineSearch } from "react-icons/ai";
import { useUserContext } from "../../context/UserContext";
import axios from "../../services/axiosInstance";
import { UserRoles } from "../../utils/types";


const Row = ({ item }: any) => {
    const { AppButton, setLoading } = useButton()
    const [sendingMessage, setSendingMessage] = useState(false)

    const handleInviteUsers = () => {
        setLoading(true)
        inviteUsers(item?.event?._id, [{ name: item?.name, email: item?.email }], false)
            .then(res => {
                toast(res.data?.message).success()
                setLoading(false)
            }).catch(err => {
                toast(err?.response?.data?.message).error()
                setLoading(false)
            })
    }

    async function sendMessageViaKingchat(kingschatUserId: string, qrcode: string) {
        try {
            setSendingMessage(true)
            const token = retrieveData()
            let accessToken = token;
            let qrcodeUrl = qrcode;

            if (!qrcodeUrl) {
                const res = await axios.get(`/admin/generate-qrcode/${item._id}`)
                if (!res.data?.qrcode)
                    throw new Error(`Couldn't generate QRCode for ${item?.name}!`)

                qrcodeUrl = res?.data?.qrcode;
            }

            if (!token) {
                const authenticationTokenResponse = await kingsChatWebSdk.login({
                    clientId: "e2929353-16f6-49ca-a1b9-92865720a587",
                    scopes: ["send_chat_message"]
                })

                accessToken = authenticationTokenResponse.accessToken;
                storeData(accessToken)
            }
            if (!accessToken) throw new Error("Login failed!")
            await kingsChatWebSdk.sendMessage({
                accessToken,
                // message: qrcodeUrl,
                message: `Your Access Badge to ${item?.event?.name || "--"}.\n
                Dear ${item?.name},\n
                This event is on ${item?.event?.date?.toString() || "--"}.\n
                Kindly click on the link below to view your QR Code and have it displayed for scanning while at the Angel Court Exhibitions & Seminars.\n
                ${qrcodeUrl}\n
                Best regards,\n
                Delegate Capture Pro Team\n
                LWPM/ROR Exhibitions & Seminars`,
                userIdentifier: kingschatUserId
            })
            toast("Successfuly sent message").success()
            setSendingMessage(false)
        } catch (error) {
            delData()
            setSendingMessage(false)
            //@ts-ignore
            toast(error?.message || error?.response?.data?.message || `Couldn't send QRCode to ${item?.name} via kingschat!`).error()
        }
    }

    return (
        <tr key={item?._id}>
            <td>
                <Flex direction={"column"}>
                    <Text fw={600} size={"sm"}>{item?.name}</Text>
                    <Text c={"dimmed"} size={"xs"}>{item?.email}</Text>
                </Flex>
            </td>
            <td>{item?.event?.name}</td>
            <td>
                <Flex direction={"column"}>
                    <Text fw={600} size={'sm'}>{item?.invitedBy?.name || "--"}</Text>
                    <Text c={"dimmed"} size={"xs"}>
                        {new Date(item?.createdAt).toLocaleTimeString('en-GB', { day: '2-digit', month: "2-digit", year: "numeric" }).split(",")[0].trim()
                        }</Text>
                </Flex>
            </td>
            <td>
                <Flex direction={"column"}>
                    {item?.kingschatUserId && <>
                        <Button
                            loading={sendingMessage}
                            onClick={() => sendMessageViaKingchat(item?.kingschatUserId as string, item?.qrcode as string)}
                            // size="xs"
                            className="bg-color-1 hover:bg-color-1">Via Kingschat</Button>
                        <Divider label="or" orientation="horizontal"
                            labelPosition="center" />
                    </>}
                    <AppButton
                        title="Via Email"
                        handleClick={handleInviteUsers} />
                </Flex>
            </td>
            {/* <td>{evt?.name}</td> */}
        </tr>
    )
}

const EventsDelegates = () => {
    const [delegates, setDelegates] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const { eventId } = useParams()
    const [loading, setIsLoading] = useState(false)
    const [entry, setEntry] = useState("")
    const { user } = useUserContext()
    const [sendingKingchatMessage, setSendingKingchatMessage] = useState(false)

    useEffect(() => {
        if (!eventId) return;
        setIsLoading(true)
        eventDelegates(eventId).then(res => {
            setDelegates(res.data.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [eventId])

    const { PaginationBtn, data } = usePagination(!entry ? delegates : searchResults)

    const excelHeader = [
        { label: "Event ID", key: "event._id" },
        { label: "Event Name", key: "event.name" },
        { label: "Name", key: "name" },
        { label: "Country", key: "country" },
        { label: "Email", key: "email" },
        { label: "Invited By", key: "invitedBy.name" },
        { label: "Time", key: "createdAt" },
    ]
    const { CSVBtn } = useCSVExport(!entry ? delegates : searchResults, excelHeader)

    const handleSearch = _debounce(async (query: string) => {
        if (!query || !eventId) return;
        try {
            const response = await eventDelegates(eventId, query)
            if (response?.data?.data.length > 0) {
                setSearchResults(response.data?.data)
            }
            if (response?.data?.data.length === 0) {
                setSearchResults([])
            }
        } catch (error: any) {
            console.log(JSON.stringify(error.response, null, 5))
        }
    }, 300)


    async function sendBulkMessageViaKingchat() {
        try {
            setSendingKingchatMessage(true)
            const token = retrieveData()
            let accessToken = token as string;

            const kingschatDelegates = delegates?.filter((d: any) => !!d?.kingschatUserId) || []
            const messagesSent = new Map()
            messagesSent.clear()
            if (!kingschatDelegates.length) return toast("No Delegates registered with Kingchat!").error()

            if (!token) {
                const authenticationTokenResponse = await kingsChatWebSdk.login({
                    clientId: "e2929353-16f6-49ca-a1b9-92865720a587",
                    scopes: ["send_chat_message"]
                })

                accessToken = authenticationTokenResponse.accessToken;
                storeData(accessToken)
            }
            if (!accessToken) throw new Error("Login failed!")


            await Promise.all(kingschatDelegates.map(async (delegate: any) => {
                let qrcodeUrl = delegate?.qrcode;

                if (!qrcodeUrl) {
                    const res = await axios.get(`/admin/generate-qrcode/${delegate._id}`)
                    if (!res.data?.qrcode)
                        throw new Error(`Couldn't generate QRCode for ${delegate?.name}!`)

                    qrcodeUrl = res?.data?.qrcode;
                }

                await kingsChatWebSdk.sendMessage({
                    accessToken,
                    // message: qrcodeUrl,
                    message: `Your Access Badge to ${delegate?.event?.name || "--"}.\n
                Dear ${delegate?.name},\n
                This event is on ${delegate?.event?.date?.toString() || "--"}.\n
                Kindly click on the link below to view and Download your QR Code and have it displayed for scanning while at the Angel Court Exhibitions & Seminars.\n
                ${qrcodeUrl}\n
                Best regards,\n
                Delegate Capture Pro Team\n
                LWPM/ROR Exhibitions & Seminars`,
                    userIdentifier: delegate?.kingschatUserId
                })
                messagesSent.set(delegate?.kingschatUserId, qrcodeUrl)
            }))
            setSendingKingchatMessage(false)

            // if (kingschatDelegates.length === messagesSent.size) return toast("Successfuly sent message").success()
            // else return toast(`Sent message to only ${messagesSent.size} delegates!`)
            toast("Successfuly sent message").success()
        } catch (error) {
            delData()
            setSendingKingchatMessage(false)
            //@ts-ignore
            toast(error?.message || error?.response?.data?.message).error()
        }
    }


    return (
        <>
            <Flex className="flex flex-row items-center justify-between">
                <div
                    className="flex flex-col w-full"
                >
                    <BackBtn to={user?.role === UserRoles.ADMIN ? "/dashboard/events" : "/dashboard"} />
                    <h1 className="my-1 font-3">Event Delegates</h1>
                </div>
                {(delegates.length > 0) || (searchResults.length > 0) ? <Button
                    className="bg-color-1"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button> : null}
            </Flex>
            <Card
                mih={500}
                padding={20}>
                <Skeleton visible={loading}>
                    <ScrollArea>
                        {delegates.length > 0 || searchResults.length > 0 ?
                            <Flex justify={"space-between"} w={"100%"} direction={"column"}>
                                <TextInput
                                    onChange={({ target }) => {
                                        setEntry(target.value)
                                        handleSearch(target.value)
                                    }}
                                    icon={<AiOutlineSearch />}
                                    placeholder="Search delegates" className="h-[70px] w-full" />
                                <Flex justify={"right"}>
                                    <Tooltip
                                        position="top"
                                        withArrow
                                        label={"This applies to all delegates"}>
                                        <Button
                                            size="xs"
                                            className="bg-color-1 hover:bg-color-1"
                                            onClick={sendBulkMessageViaKingchat}
                                            loading={sendingKingchatMessage}>Send QRCode Via Kingschat</Button>
                                    </Tooltip>
                                </Flex>
                            </Flex>
                            : null}
                        <Table verticalSpacing={'xs'}>
                            <thead>
                                <tr>
                                    <th>Name/Email</th>
                                    <th>Event Name</th>
                                    <th>Invited By/Date</th>
                                    <th>Resend QrCode</th>
                                </tr>
                            </thead>

                            <tbody>{data()?.map(item => <Row item={item} />)}</tbody>
                        </Table>
                        {delegates.length === 0 &&
                            <Flex className="items-center justify-center mt-5">
                                <Text>No data found</Text>
                            </Flex>
                        }
                    </ScrollArea>
                </Skeleton>
                <PaginationBtn />

            </Card>
        </>
    );
}

export default EventsDelegates;