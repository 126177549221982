/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    createContext,
    useEffect, useState,
    useReducer, useContext,
    ReactNode
} from 'react';
import jwtDecode from 'jwt-decode';
import AppLoader from "../components/AppLoader"
import { getUserProfile } from '../services/userService';
import { toast } from '../utils/helpers';
import { UserRoles } from '../utils/types';

type User = {
    name?: string;
    id?: string;
    email?: string;
    password?: null;
    country?: string;
    token?: string;
    role?:UserRoles
}

export enum Action_Type {
    USER_TOKEN = "USER_TOKEN",
    USER_PROFILE = "USER_PROFILE",
    LOGOUT_USER = "LOGOUT_USER"
}
export const DELEGATE_CAPTURE_USER_TOKEN = 'DELEGATE_CAPTURE_USER_TOKEN';
export const USER_TOKEN = "USER_TOKEN";
export const USER_PROFILE = "USER_PROFILE"
export const LOGOUT_USER = "LOGOUT_USER";

interface UserContextType {
    user?: User,
    userDispatch?: React.Dispatch<{ payload?: any, type: Action_Type }>
}
const UserContext = createContext<UserContextType>({});



export const userToken = () => localStorage.getItem(DELEGATE_CAPTURE_USER_TOKEN)
export const removeUserToken = () => localStorage.removeItem(DELEGATE_CAPTURE_USER_TOKEN)

export function decodeUserToken(token: string) {
    try {
        const decoded: any = { ...jwtDecode(token) }
        if (token) return { ...decoded, token }
        else return null;
    }
    catch (ex) {
        console.log(ex)
    }
}

const userReducer = (state: any, action: { payload?: any, type: Action_Type }) => {
    switch (action.type) {
        case Action_Type.USER_PROFILE:
            localStorage.setItem(DELEGATE_CAPTURE_USER_TOKEN, action.payload.token)
            return action.payload;
        case Action_Type.LOGOUT_USER:
            removeUserToken()
            return {};
        default:
            return null
    }
}


const UserContextProvider = ({ children }: { children: ReactNode }) => {
    const [loading, setLoading] = useState(false);

    //local user info
    const [user, userDispatch] = useReducer(userReducer, { name: "" }, function () {
        // const token = userToken()
        // if (token) return decodeUserToken(token);
        // else return null;
    })

    const contextValue: UserContextType = {
        user,
        userDispatch: userDispatch as React.Dispatch<{ payload?: any; type: Action_Type }>,
    };

    const token = userToken()
    useEffect(() => {
        if (!token || user?.name) {
            setLoading(false)
            return;
        };

        if (token && !user?.name) {
            setLoading(true)
            getUserProfile().then(res => {
                setLoading(false)
                userDispatch({
                    type: Action_Type.USER_PROFILE,
                    payload: {...res.data.data, token}
                })
            }).catch(err => {
                console.log(err)
                setLoading(false)
                userDispatch({
                    type: Action_Type.LOGOUT_USER,
                })
                toast(err?.response?.data?.message).error()
            })
        }
    }, [token, user?.name])

    return (
        <UserContext.Provider value={contextValue}>
            {loading && <AppLoader />}
            {!loading && children}
        </UserContext.Provider>
    )

}

export default UserContextProvider;


export const useUserContext = () => useContext(UserContext);