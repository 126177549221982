import { Modal, Flex, Button, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

const useConfirmationModal = (handleYes: () => void) => {
    const [opened, { open, close }] = useDisclosure(false);

    const handleYesClick = () => {
        handleYes()
        close()
    }

    const handleNoClick = () => {
        close()
    }

    const ConfirmationModal = ({ modalTitle = "Are you sure, you want to perform this action ?" }) => {
        return (
            <Modal opened={opened} onClose={close} centered>
                <Text className="text-center font-semibold text-lg">{modalTitle}</Text>
                <Flex className="flex-row items-center justify-around w-[150px] mx-auto h-[100px]">
                    <Button className="bg-black hover:bg-slate-700" onClick={handleNoClick}>No</Button>
                    <Button className="bg-color-3 hover:bg-red-700" onClick={handleYesClick}>Yes</Button>
                </Flex>
            </Modal>)
    }

    return { open, ConfirmationModal }
}

export default useConfirmationModal;