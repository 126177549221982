import ViewEvents from "../../components/dashboard/admin/ViewEvents";

const Events = () => {
    return ( 
        <>
        <ViewEvents/>
        </>
     );
}
 
export default Events;