import { Card, TextInput, Flex, Modal, Stepper, Group, Button, List, ScrollArea, Text } from "@mantine/core";
import { DateTimePicker } from '@mantine/dates';

import { FormEvent, useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import {MdOutlineDriveFileRenameOutline} from "react-icons/md"
import useButton from "../../../hooks/useButton";
import { inputStyles, toast } from "../../../utils/helpers";
import { createEvent } from "../../../services/userService";
import DelegatesInviteModal from "../DelegatesInviteModal";
import { User } from "../../../utils/types";

const AdminDashboard = () => {
    const { AppButton, setLoading } = useButton()
    const { AppButton: AppButton2} = useButton()

    const [opened, { open, close }] = useDisclosure(false);
    const [opened2, { open: open2, close: close2 }] = useDisclosure(false);
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    useEffect(() => {
        // prevStep()
        // open2()
    }, [])

    const [details, setDetails] = useState({
        users: [],
        name: "",
        date: ""
    })
    const [uploadedUsers, setUploadedUsers] = useState<User[]>([])

    const [key, setKey] = useState(0)

    const handleInputChange = (name: string, value: string) => setDetails({ ...details, [name]: value })

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        createEvent({ ...details, users: uploadedUsers }).then(res => {
            toast(res.data?.message).success()
            setDetails({
                users: [],
                name: "",
                date: ""
            })
            setUploadedUsers([])
            setKey(item => item + 1)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            toast(err?.reponse?.data?.message).error()
        })
    }


    const mdWidth = 70 / 100 * window.innerWidth
    const smWidth = 90 / 100 * window.innerWidth



    return (<>
        <div className="flex flex-row justify-end">
            {/* <h1>Create Event</h1> */}
            <Link
            className="my-4 font-3"
             to={"#"} onClick={() => {
                setActive(0)
                open2()
            }}>Need Help?</Link>
        </div>
        <Card
            mih={500}
            padding={20}>
            <Flex direction={'column'} className="w-full sm:w-[50%] sm:mx-auto ml-0 justify-around mt-5">
            <h1>Create Event</h1>
                <form onSubmit={handleFormSubmit}>
                    <TextInput
                    icon={<MdOutlineDriveFileRenameOutline/>}
                        onChange={(e) => handleInputChange("name", e.target.value)}
                        value={details.name}
                        required
                        className="font-1 w-full"
                        size="md"
                        placeholder="Name"
                        mt={10}
                        sx={{ input: inputStyles }}
                    />
                    <DateTimePicker
                    icon={<BsFillCalendarCheckFill/>}
                        key={key}
                        required
                        onChange={(value) => handleInputChange("date", `${value}`)}
                        placeholder="Event Time"
                        className="font-1 w-full"
                        mt={10}
                        sx={{ button: inputStyles }}
                    />

                    <Button
                    mt={10}
                     className="bg-[#f8f9fa] text-black w-full hover:bg-[#f8f9fa]"
                      onClick={open} >Add Delegates</Button>

                    <AppButton title="Create" />
                </form>
            </Flex>

        </Card>

        <DelegatesInviteModal opened={opened} close={close} key={key} setUsers={setUploadedUsers} users={uploadedUsers} />

        <Modal
            //@ts-ignore
            size={{ md: '70vw', sm: "100vw" }}
            opened={opened2}
            onClose={close2}
            padding={10}
            centered>
            <ScrollArea>
                <Stepper 
                
                className="px-3"
                active={active} onStepClick={setActive}
                 breakpoint="sm"
                //   miw={'80%'}
                  >
                    <Stepper.Step 
                    label="First step" description="Create an event">
                        <List listStyleType="number"
                        className="px-1"
                        >
                            <List.Item>On the main page after successfully logging in, enter a new event name.</List.Item>
                            <List.Item>After entering the event name and date, click on the invite delegates.</List.Item>
                            <List.Item>A popup with two options to either manualy add delegates or Upload a valid CSV file containing delegates.</List.Item>
                            <List.Item>Click on done, the modal closes and you can now click on the Create button.</List.Item>
                        </List>
                    </Stepper.Step>
                    <Stepper.Step label="Second step" description="View events">
                        <List
                         className="px-1"
                         listStyleType="number">
                            <List.Item>Click on Events menu to view events created</List.Item>
                            <List.Item>You will get navigated to the events page on the top right corner there's a button to export all events to a CSV file.</List.Item>
                            <List.Item>You export all events details as a CSV file.</List.Item>
                            <List.Item>On events table, you can copy registration link to the even and view all delegates invited.</List.Item>
                            <List.Item>From events page, you can invite more delegates and view details.</List.Item>
                        </List>
                    </Stepper.Step>
                    <Stepper.Step label="Final step" description="Settings/Logout">
                        <List 
                         className="px-1"
                        listStyleType="number">
                            <List.Item>You can update your profile and password from here.</List.Item>
                            <List.Item>Admin can Create and add new Exhibitors.</List.Item>
                            
                        </List>
                    </Stepper.Step>
                    <Stepper.Completed>
                        <Group position="center" className="h-[50px]">
                        <Text className="font-semibold text-lg">You're ready to go 👍</Text>
                        </Group>
                    </Stepper.Completed>
                </Stepper>

                <Group position="center" mt="xl">
                    <Button variant="default" onClick={prevStep}>Back</Button>
                    <Button onClick={() => {
                        if (active === 3) {
                            close2()
                        }
                        else {
                            nextStep()
                        }
                    }} className="bg-color-1">
                        {active ===3 ? "Finish" : " Next step"}
                       
                        </Button>
                </Group>
            </ScrollArea>
        </Modal>
    </>
    );
}

export default AdminDashboard;