/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { inputStyles, toast } from '../../utils/helpers'
import useButton from '../../hooks/useButton'
import { FileInput, List, Modal, ScrollArea, Tabs, TextInput, Select } from '@mantine/core';
import * as  xlsx from "xlsx";
import { User } from '../../utils/types';
import UploadedUser from './UploadedUser';
import { Country } from 'country-state-city';
import { BiUpload } from 'react-icons/bi';
import { VscAccount } from "react-icons/vsc"
import { BsPhone } from 'react-icons/bs';
import { MdOutlineAlternateEmail, MdLocationPin, MdOutlineChurch } from "react-icons/md"
import { LuChurch } from "react-icons/lu";
interface DelegatesInviteModalProp {
    opened: boolean;
    close: () => void;
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    users: User[];
}

export default function DelegatesInviteModal({ opened, close, setUsers, users }: DelegatesInviteModalProp) {
    const { AppButton: AppButton3 } = useButton()
    const { AppButton: AppButton4 } = useButton()
    const [currentOption, setCurrentOption] = useState<"option1" | "option2" | null>("option1")
    const [key, setKey] = useState(0)

    const [user, setUser] = useState({ name: "", email: "", phone: "", country: "", zonal: "", church: "" })

    const handleUsersUpload = (file: File) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (!e) return;
                //@ts-ignore
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                if (!json.every((item) => item?.hasOwnProperty('name') && item?.hasOwnProperty('email'))) {
                    toast('Invalid File Uploaded!').error()
                    setKey(prevKey => prevKey + 1)
                    return;
                }
                //@ts-ignore
                setUsers([...json])
            };

            reader.readAsArrayBuffer(file);
        }
    }

    const handleClick = (e: any) => {
        e.preventDefault()
        if (!user.name || !user.email) {
            toast('All Fields are required!').error()
            return;
        }
        const userExists = users.find(item => item.email === user.email)
        if (userExists) {
            toast('You have added this user already!').error()
            return;
        }
        setUsers([...users, {...user, country:JSON.parse(user.country)?.name}])
        setUser({ name: "", email: "", phone: "", country: "", zonal: "", church: "" })
        // setKey(prevKey => prevKey + 1)
    }

    const handleClose = () => {
        setCurrentOption(null)
        setUser({ name: "", email: "", phone: "", country: "", zonal: "", church: "" })
        setKey(prevKey => prevKey + 1)
        close()
    }

    const handleDownload = () => {
        console.log(process.env.PUBLIC_URL)
        // Get the URL of the CSV file in the public folder
        const fileUrl = process.env.PUBLIC_URL + '/template.xlsx';

        // Create a temporary `a` element and set its attributes
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = 'template.xlsx';

        // Trigger a click event to download the file
        a.click();

        // Remove the temporary `a` element
        // document.body.removeChild(a);
    };

    return (
        <Modal
            opened={opened}
            onClose={() => {
                setUsers([])
                close()
            }
            }
            size={'md'}
            closeOnClickOutside={false}
            padding={10}
            centered>
            <ScrollArea>
                <Tabs defaultValue="gallery">
                    <Tabs.List>
                        <Tabs.Tab value="gallery" onClick={() => setCurrentOption("option1")}>Add Delegates</Tabs.Tab>
                        <Tabs.Tab value="messages" onClick={() => setCurrentOption("option2")}>Upload Delegates</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="gallery" pt="xs">
                        {currentOption === "option1" &&
                            <List listStyleType="disc">
                                {users.map((user, key) =>
                                    <div key={key}>
                                        <UploadedUser name={user.name}
                                            email={user.email}
                                            users={users}
                                            setUsers={setUsers}
                                        />
                                    </div>

                                )}
                            </List>}
                        <form onSubmit={handleClick} className=''>
                            <TextInput
                                icon={<VscAccount />}
                                onChange={({ target }) => setUser({ ...user, name: target.value })}
                                value={user.name}
                                required
                                className="font-1 w-full"
                                size="md"
                                placeholder="Full Name"
                                mt={10}
                                sx={{ input: inputStyles }}
                            />
                            <TextInput
                                icon={<MdOutlineAlternateEmail />}
                                onChange={({ target }) => setUser({ ...user, email: target.value })}
                                value={user.email}
                                required
                                className="font-1 w-full"
                                size="md"
                                placeholder="Email"
                                mt={10}
                                sx={{ input: inputStyles }}
                            />
                            <TextInput
                                icon={<BsPhone />}
                                onChange={({ target }) => setUser({ ...user, phone: target.value })}
                                value={user.phone}
                                required
                                className="font-1 w-full"
                                size="md"
                                placeholder="Phone"
                                mt={10}
                                type='tel'
                                sx={{ input: inputStyles }}
                            />
                            <Select
                                icon={<MdLocationPin />}
                                onChange={(value) => setUser({
                                    ...user,
                                    //@ts-ignore
                                    country: value
                                })}
                                value={user.country}
                                mt={10}
                                required
                                size='md'
                                className="w-full"
                                placeholder="Country"
                                searchable
                                nothingFound="No options"
                                clearable
                                data={[...Country.getAllCountries().map(data => {
                                    return { value: JSON.stringify({ isoCode: data.isoCode, name: data.name }), label: data.name }
                                })]}
                                sx={{
                                    input: inputStyles
                                }}
                            />
                            <TextInput
                                icon={<LuChurch />}
                                onChange={({ target }) => setUser({ ...user, church: target.value })}
                                value={user.church}
                                className="font-1 w-full"
                                size="md"
                                placeholder="Church"
                                mt={10}
                                sx={{ input: inputStyles }}
                            />
                            <TextInput
                                icon={<MdOutlineChurch />}
                                onChange={({ target }) => setUser({ ...user, zonal: target.value })}
                                value={user.zonal}
                                className="font-1 w-full"
                                size="md"
                                placeholder="Zone"
                                mt={10}
                                sx={{ input: inputStyles }}
                            />

                            <div className='flex flex-col sm:flex-row justify-between'>
                                <div className='sm:w-[48%] w-full'>
                                    <AppButton3 title="Add"
                                        styles="hover:bg-[#f8f9fa] bg-[#f8f9fa] text-black hover:text-black" />
                                </div>
                                <div className='sm:w-[48%] w-full'>
                                    <AppButton4 title={"Done"} handleClick={handleClose} />
                                </div>
                            </div>
                        </form>
                    </Tabs.Panel>

                    <Tabs.Panel value="messages" pt="xs">
                        {currentOption === "option2" &&
                            <List listStyleType="disc">
                                {users.map((user, key) =>
                                    <div key={key}>
                                        <UploadedUser name={user.name}
                                            email={user.email}
                                            users={users}
                                            setUsers={setUsers}
                                        />
                                    </div>

                                )}
                            </List>}
                        <a href='#' onClick={handleDownload}>Download template</a>
                        <FileInput
                            key={key}
                            icon={<BiUpload />}
                            onChange={handleUsersUpload}
                            mt={20}
                            placeholder="Upload Invites"
                        />
                        <AppButton4 title="Done"
                            handleClick={handleClose} />

                    </Tabs.Panel>
                </Tabs>

            </ScrollArea>

        </Modal>

    )
}
