/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button, Card, Flex, ScrollArea,
    Skeleton, Table, Text, TextInput
} from "@mantine/core";
import { useEffect, useState } from "react";
import { inviteUsers, eventDelegates, getDelegates, getExhibitorDelegates, } from "../../services/userService";
import { toast } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import usePagination from "../../hooks/usePagination";
import { CiExport } from "react-icons/ci";
import useCSVExport from "../../hooks/useCSVExport";
import useButton from "../../hooks/useButton";
import BackBtn from "../../components/BackBtn";
//@ts-ignore
import _debounce from 'lodash.debounce';
import { AiOutlineSearch } from "react-icons/ai";


const Row = ({ item }: any) => {
    const { AppButton, setLoading } = useButton()

    const handleInviteUsers = () => {
        if(!item?.event?._id) return;
        setLoading(true)
        inviteUsers(item?.event?._id, [{ name: item?.name, email: item?.email }], false, true).then(res => {
            toast(res.data?.message).success()
            setLoading(false)
        }).catch(err => {
            toast(err?.response?.data?.message).error()
            setLoading(false)
        })
    }

    return (
        <tr key={item?._id}>
            <td>{item?.name}</td>
            <td>{item?.email}</td>
            <td>{item?.event?.name}</td>
            <td>{item?.invitedBy?.name}</td>
            <td>{new Date(item?.createdAt).toLocaleTimeString('en-GB', { day: '2-digit', month: "2-digit", year: "numeric" }).split(",")[0].trim()
            }</td>
            <td><AppButton title="Resend" handleClick={handleInviteUsers} /></td>
            {/* <td>{evt?.name}</td> */}
        </tr>
    )
}

const ExhibitorDelegates = () => {
    const [delegates, setDelegates] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const { eventId } = useParams()
    const [loading, setIsLoading] = useState(false)
    const [entry, setEntry] = useState("")

    useEffect(() => {
        setIsLoading(true)
        getDelegates().then(res => {
            setDelegates(res.data.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [])

    const { PaginationBtn, data } = usePagination(!entry ? delegates : searchResults)

    const excelHeader = [
        { label: "Event ID", key: "event._id" },
        { label: "Event Name", key: "event.name" },
        { label: "Name", key: "name" },
        { label: "Country", key: "country" },
        { label: "Email", key: "email" },
        { label: "Invited By", key: "invitedBy.name" },
        { label: "Time", key: "createdAt" },
    ]
    const { CSVBtn } = useCSVExport(!entry ? delegates : searchResults, excelHeader)

    // const handleSearch = _debounce(async (query: string) => {
    //     if (!query || !eventId) return;
    //     try {
    //         const response = await eventDelegates(eventId, query)
    //         if (response?.data?.data.length > 0) {
    //             setSearchResults(response.data?.data)
    //         }
    //         if (response?.data?.data.length === 0) {
    //             setSearchResults([])
    //         }
    //     } catch (error: any) {
    //         console.log(JSON.stringify(error.response, null, 5))
    //     }
    // }, 300)

    return (
        <>
            <Flex className="flex flex-row items-center justify-between">
                <div
                    className="flex flex-col w-full"
                >
                    <BackBtn to="/dashboard" />
                    <h1 className="my-1 font-3">Delegates</h1>
                </div>
                {(delegates.length > 0) || (searchResults.length > 0) ? <Button
                    className="bg-color-1"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button> : null}
            </Flex>
            <Card
                mih={500}
                padding={20}>
                <Skeleton visible={loading}>
                    <ScrollArea>
                        {/* <TextInput
                            onChange={({ target }) => {
                                setEntry(target.value)
                                handleSearch(target.value)
                            }}
                            icon={<AiOutlineSearch />}
                            placeholder="Search delegates" className="h-[70px]" /> */}
                        <Table verticalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Event Name</th>
                                    <th>Invited By</th>
                                    <th>Date Invited</th>
                                </tr>
                            </thead>

                            <tbody>{data()?.map(item => <Row item={item} />)}</tbody>
                        </Table>
                        {delegates.length === 0 &&
                            <Flex className="items-center justify-center mt-5">
                                <Text>No data found</Text>
                            </Flex>
                        }
                    </ScrollArea>
                </Skeleton>
                <PaginationBtn />

            </Card>
        </>
    );
}

export default ExhibitorDelegates;