
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserRoles } from '../../utils/types';
import { useUserContext, userToken } from '../../context/UserContext';

const PrivateRoutes = ({ children, roles }:
    { children: any, roles: UserRoles[] }) => {
    const token = userToken()
    const { user } = useUserContext()
    const { pathname } = useLocation()

    const checkPath = (path: string) => pathname.includes(path)

    const redirectUrl = () => {
        if (user?.role === UserRoles.SUB_ADMIN) {
            if (pathname === "/dashboard/events"
                || pathname === "/dashboard/sub-admins"
                // || pathname === "/dashboard/delegates"
                ) {
                return "/"
            }
        }
        // if (user?.role === UserRoles.ADMIN) {
        //     if (pathname === "/dashboard/exhibitor-delegates") {
        //         return "/"
        //     }
        // }

        return pathname;
    }


    const url = redirectUrl()
    console.log("redirectUrl", url)

    if ((!token) || (user?.role && !roles.includes(user?.role))) {
        return <Navigate to={`/login${url ? `?redirect=${url}` : ''}`} />
    }
    return children
}

export default PrivateRoutes;
