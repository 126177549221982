import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";

const BackBtn = ({to}:{to:string}) => {
    return (
        <Link to={to} className="flex flex-row items-center mb-2">
            <AiOutlineArrowLeft />
            <Text>Go Back</Text>
        </Link>
    );
}

export default BackBtn;