import { Card, Tabs } from "@mantine/core";
import Profile from "../../components/dashboard/settings/Profile";
import Security from "../../components/dashboard/settings/Security";
import CreateSubAdmin from "../../components/dashboard/settings/admin/CreateSubAmin";
import { useUserContext } from "../../context/UserContext";
import { UserRoles } from "../../utils/types";

const Settings = () => {
    const { user } = useUserContext()
    return (
        <Card
            padding={20}
            mih={400}>
            <Tabs defaultValue="profile" color="#000">
                <Tabs.List>
                    <Tabs.Tab value="profile">Account</Tabs.Tab>
                    {user?.role === UserRoles.ADMIN && <Tabs.Tab value="createSubAdmin">Create Exhibitors</Tabs.Tab>}
                    <Tabs.Tab value="security">Security</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="profile">
                    <Profile />
                </Tabs.Panel>
                
                {user?.role === UserRoles.ADMIN &&
                    <Tabs.Panel value="createSubAdmin">
                        <CreateSubAdmin />
                    </Tabs.Panel>}

                <Tabs.Panel value="security">
                    <Security />
                </Tabs.Panel>
            </Tabs>
        </Card>
    );
}

export default Settings;