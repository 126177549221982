import {
    BackgroundImage, Flex, Container, Paper, Group, Image
} from '@mantine/core';
//@ts-ignore
import layerImg from "../images/background.jpg";
//@ts-ignore
import logo from "../images/logo.png";

function PageWrapper({ children, height }: any) {
    const userOnLogin = window.location.pathname === "/" || window.location.pathname === "/login";
    return (
        <BackgroundImage
            src={layerImg}
            className='bg-cover bg-no-repeat'
            style={{ backgroundAttachment: "fixed" }} // Set background to fixed
        >
            <Flex direction={"column"} mih={"100vh"} p={0} m={0}>
                <Container
                    mt={userOnLogin ? 60 : 70}
                    size={400}
                    className='w-[100%]'>
                    <Group position='center'>
                        <Image src={logo} width={100} />
                    </Group>
                    <Paper
                        maw={400}
                        mt={30}
                        shadow="md"
                        p={30}
                        className={`${height ?? "min-h-[500px]"} mb-10`}
                        radius="lg"
                        style={{ minHeight: "300px", paddingBottom: "20px", overflow: "hidden" }} // Fixed height and overflow
                    >
                        {children}
                    </Paper>
                </Container>
            </Flex>
        </BackgroundImage>
    );
}

export default PageWrapper;
