import { Flex } from "@mantine/core";
import { useRef, useState } from "react";
import useButton from "../../../hooks/useButton";
import PasswordInput from "../../PasswordInput";
import { updatePassword } from "../../../services/userService";
import { toast } from "../../../utils/helpers";


const Security = () => {

    const { AppButton, setLoading } = useButton()

    const inputRef = useRef(null)

    const [password, setPassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    })

    const handleInputchange = ({ target: { name, value } }: { target: { name: string, value: string } }) => setPassword({ ...password, [name]: value })

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (password.confirmPassword !== password.newPassword) {
            toast("Passwords don't match").error()
            return;
        }
        setLoading(true)
        updatePassword({ currentPassword: password.currentPassword, newPassword: password.newPassword })
            .then(res => {
                console.log(res.data)
                setLoading(false)
                toast(res.data?.message).success()
            }).catch(err => {
                console.log(err)
                setLoading(false)
                toast(err?.response?.data?.message).error()
            })
    }

    return (
        <Flex className="w-full md:w-[60%] flex flex-col md:mx-auto lg:mx-0 justify-around mt-[30px]">
            <form onSubmit={handleSubmit}>
                <PasswordInput
                    name="currentPassword"
                    value={password.currentPassword}
                    handleChange={handleInputchange}
                    placeholder="Current Password"
                />
                <PasswordInput
                    name="newPassword"
                    value={password.newPassword}
                    handleChange={handleInputchange}
                    placeholder="New Password" />

                <PasswordInput
                    name="confirmPassword"
                    value={password.confirmPassword}
                    handleChange={handleInputchange}
                    placeholder="Confirm Password" />
                <AppButton title="Update" />
            </form>
        </Flex>
    );
}

export default Security;