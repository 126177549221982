import { Divider, Select, Skeleton, Text, TextInput } from "@mantine/core";
import axios from "axios";
import { Country } from "country-state-city";
import kingsChatWebSdk from 'kingschat-web-sdk';
import 'kingschat-web-sdk/dist/stylesheets/style.min.css';
import { loginOptionsI } from "kingschat-web-sdk/dist/ts/interfaces";
import { useEffect, useState } from "react";
import { BsPhone } from "react-icons/bs";
import { LuChurch } from "react-icons/lu";
import { MdLocationPin, MdOutlineAlternateEmail, MdOutlineChurch } from "react-icons/md";
import { VscAccount } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper";
import useButton from "../../hooks/useButton";
import { getEvent, inviteUsers } from "../../services/userService";
import { inputStyles, toast } from "../../utils/helpers";

// function refreshKingsChatAuthenticationToken() {
//   kingsChatWebSdk.refreshAuthenticationToken(refreshAuthenticationTokenOptions)
//   .then(authenticationTokenResponse => ...)
//   .catch(error => ...);
// }


// async function sendKingsChatMessageRequest(sendMessageOptions: sendMessageOptionsI) {
//     return await kingsChatWebSdk.sendMessage(sendMessageOptions)
// }

const RegisterForEvent = () => {
    const userDefaultState = { name: "", email: "", phone: "", country: "", zonal: "", church: "" }
    const [user, setUser] = useState(userDefaultState)
    const { AppButton, setLoading } = useButton()
    const [event, setEvent] = useState({
        _id: "",
        name: ""
    })
    const { eventId } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!eventId) return;
        setIsLoading(true)
        getEvent(eventId).then(res => {
            setIsLoading(false)
            setEvent(res.data?.data)
        }).catch(err => {
            setIsLoading(false)
            toast(err?.response?.data?.message).error()
            navigate("*")
        })
    }, [eventId])

    const handleInviteUser = (e: any) => {
        e.preventDefault()
        if (!user.name) {
            toast('Name must not be empty!').error()
            return;
        }
        if (!user.email) {
            toast('Email must not be empty!').error()
            return;
        }
        setLoading(true)
        inviteUsers(event?._id, [{ ...user, country: JSON.parse(user.country)?.name }], true)
            .then(res => {
                toast(res.data?.message).success()
                setLoading(false)
                setUser({ name: "", email: "", phone: "", country: "", zonal: "", church: "" })
            }).catch(err => {
                toast(err?.response?.data?.message).error()
                setLoading(false)
            })
    }

    async function loginWithKingsChat(loginOptions: loginOptionsI, eventId: string) {
        try {
            const authenticationTokenResponse = await kingsChatWebSdk.login(loginOptions)
            // console.log("authenticationResponse", authenticationTokenResponse)
            const accessToken = authenticationTokenResponse.accessToken;
            if (!accessToken) throw new Error("Login failed!")
            if (accessToken) {
                const res = await axios.get("https://connect.kingsch.at/developer/api/profile",
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    })

                const profile = res.data?.profile;
                console.log("profile", profile)
                if (!profile) throw new Error("Couldn't retrieve profile!");
                const res2 = await inviteUsers(eventId,
                    [{
                        email: profile?.email || "N/A",
                        name: profile?.name || "N/A",
                        username: profile?.username || "N/A",
                        phone_number: profile?.phone_number || "N/A",
                        // country: JSON.parse(user.country)?.name,
                        kingschatUserId: profile.id,
                        // zonal: user.zonal,
                        // church: user.church,
                    }], true)

                setUser(userDefaultState)
                // await sendKingsChatMessageRequest({
                //     accessToken,
                //     message: "Godwin testing innt",
                //     // userIdentifier: profile.id
                //     userIdentifier: "65142f970b9e60e8fc7fe737"
                // })
                toast("Successfully registered for this event, details would be sent to kingschat inbox soon!").success()

            }
        } catch (error) {
            //@ts-ignore
            toast(error?.message || error?.response?.data?.message).error()
        }
    }



    function KingsChatButton({ eventId }: { eventId: string }) {
        return <a className="kc-web-sdk-btn !my-1" onClick={
            () => {
                // if (!user.country || !user.zonal || !user.church) {
                //     toast("All values are required!").error()
                //     return;
                // }
                loginWithKingsChat({
                    clientId: "e2929353-16f6-49ca-a1b9-92865720a587",
                    scopes: ["send_chat_message"]
                }, eventId)
            }} />
    }


    return (
        <>
            <PageWrapper>
                <Skeleton visible={isLoading}>
                    <Text
                        color="dark"
                        align="center"
                        my={2}
                        className='font-bold text-lg font-1'
                    >
                        Register Now For {event.name}
                    </Text>
                    <form onSubmit={handleInviteUser}>
                        <TextInput
                            icon={<VscAccount />}
                            onChange={({ target }) => setUser({ ...user, name: target.value })}
                            value={user.name}
                            required
                            className="font-1 w-full"
                            size="md"
                            placeholder="Full Name"
                            mt={10}
                            sx={{ input: inputStyles }}
                        />
                        <TextInput
                            icon={<MdOutlineAlternateEmail />}
                            onChange={({ target }) => setUser({ ...user, email: target.value })}
                            value={user.email}
                            required
                            className="font-1 w-full"
                            size="md"
                            placeholder="Email"
                            mt={10}
                            sx={{ input: inputStyles }}
                        />
                        <TextInput
                            icon={<BsPhone />}
                            onChange={({ target }) => setUser({ ...user, phone: target.value })}
                            value={user.phone}
                            required
                            className="font-1 w-full"
                            size="md"
                            placeholder="Phone"
                            mt={10}
                            type='tel'
                            sx={{ input: inputStyles }}
                        />
                        <Select
                            icon={<MdLocationPin />}
                            onChange={(value) => setUser({
                                ...user,
                                //@ts-ignore
                                country: value
                            })}
                            value={user.country}
                            mt={10}
                            required
                            size='md'
                            className="w-full"
                            placeholder="Country"
                            searchable
                            nothingFound="No options"
                            clearable
                            data={[...Country.getAllCountries().map(data => {
                                return { value: JSON.stringify({ isoCode: data.isoCode, name: data.name }), label: data.name }
                            })]}
                            sx={{
                                input: inputStyles
                            }}
                        />
                        <TextInput
                            icon={<LuChurch />}
                            onChange={({ target }) => setUser({ ...user, church: target.value })}
                            value={user.church}
                            className="font-1 w-full"
                            size="md"
                            placeholder="Church"
                            mt={10}
                            sx={{ input: inputStyles }}
                        />
                        <TextInput
                            icon={<MdOutlineChurch />}
                            onChange={({ target }) => setUser({ ...user, zonal: target.value })}
                            value={user.zonal}
                            className="font-1 w-full"
                            size="md"
                            placeholder="Zone"
                            mt={10}
                            sx={{ input: inputStyles }}
                        />

                        <AppButton title="Register" />
                        <Divider label="or" labelPosition="center" my={"xs"} />
                        <KingsChatButton eventId={event._id} />
                    </form>
                </Skeleton>
            </PageWrapper>

        </>
    );
}

export default RegisterForEvent;