import React from 'react';
import { Header, Flex, Image, Menu, Text, MediaQuery, Burger } from '@mantine/core';
import { FiHome, FiSettings } from 'react-icons/fi';
import { BiHeadphone, BiLogOutCircle } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { Action_Type, useUserContext } from "../../context/UserContext";
import { imageUrl } from '../../utils/helpers';
import { IoAddSharp } from "react-icons/io5"
import { UserRoles } from '../../utils/types';
import { FaUsersRectangle } from "react-icons/fa6"



const DashboardHeader: React.FC<any> = ({ setOpened, opened, theme }) => {
    const { user, userDispatch } = useUserContext()

    const navigate = useNavigate()

    const handleNavigation = (path: string) => navigate(path);
    const handleLogout = () => {
        if (userDispatch)
            userDispatch({
                type: Action_Type.LOGOUT_USER,
            })
        handleNavigation("/login")
    }


    return (
        <Header height={{ base: 80, md: 100 }} p="md">
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                    <Burger
                        opened={opened}
                        onClick={() => setOpened((o: any) => !o)}
                        size="sm"
                        color={theme?.colors?.gray[6]}
                        mr="xl"
                    />
                </MediaQuery>

                <Text className='font-Poppins text-lg'>Welcome,  {user?.name}</Text>
                <Flex direction={'row'} justify={'space-between'} className='absolute items-center right-5'>
                    <Menu shadow="md" width={150}>
                        <Menu.Target>
                            <Image
                                className='cursor-pointer'
                                radius={'50%'}
                                width={50}
                                height={50}
                                src={imageUrl} />
                        </Menu.Target>

                        <Menu.Dropdown>
                            {user?.role === UserRoles.ADMIN && <Menu.Item icon={<IoAddSharp size={20} />} onClick={() => handleNavigation("/dashboard")}>Create Events</Menu.Item>}
                            <Menu.Item icon={<FaUsersRectangle size={20} />} onClick={() => handleNavigation(
                                user?.role === UserRoles.ADMIN ? 
                                "/dashboard/events" : "/dashboard"
                                )}>View Events</Menu.Item>
                            <Menu.Item icon={<BiLogOutCircle size={20} />} onClick={handleLogout}>Logout</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>

                </Flex>
            </div>
        </Header>
    );
}

export default DashboardHeader;