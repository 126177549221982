import { useState } from 'react';
import { AppShell, useMantineTheme } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardHeader from "./DashboardHeader";


const DashboardLayout = () => {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);

    return (
        <AppShell
            layout='alt'
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            }}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm" 
            header={<DashboardHeader opened={opened} setOpened={setOpened} theme={theme} />}
            navbar={<Sidebar opened={opened} setOpened={setOpened} />}
        >
            <Outlet />
        </AppShell>
    );
}

export default DashboardLayout;