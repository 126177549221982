import AdminDashboard from "../../components/dashboard/admin/AdminDashboard";
import ViewEvents from "../../components/dashboard/admin/ViewEvents";
import { useUserContext } from "../../context/UserContext";
import { UserRoles } from "../../utils/types";
import AppCard from "../../components/dashboard/AppCard";
import { useEffect, useState } from "react";
import { getAttendees, getDelegates, getEvents, getExhibitorDelegates, getSubAdmins } from "../../services/userService";
import { toast } from "../../utils/helpers";
import { Skeleton } from "@mantine/core";

const Dashboard = () => {
  const { user } = useUserContext()
  const [eventsLength, setEventsLength] = useState<number>(0)
  const [delegatesLength, setDelegatesLength] = useState<number>(0)
  const [exhibitorsLength, setExhibitorsLength] = useState<number>(0)
  const [attendeesLength, setAttendesLength] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  // fetch super admin data
  const fetchData1 = () => {
    return Promise.all([
      getEvents(),
      getDelegates(),
      getSubAdmins(),
      getAttendees()
    ])
  }

  // fetch exhibitors data
  const fetchData2 = () => {
    return Promise.all([
      getDelegates(),
      getAttendees()
    ])
  }





  useEffect(() => {
    if (user?.role !== UserRoles.ADMIN) return;
    setLoading(true)
    fetchData1().then(res => {
      setEventsLength(res[0].data?.data?.length)
      setDelegatesLength(res[1].data?.data?.length)
      setExhibitorsLength(res[2].data?.data?.length)
      setAttendesLength(res[3].data?.data?.length)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast(err?.response?.data?.message).error()
    })
  }, [])

  useEffect(() => {
    if (user?.role !== UserRoles.SUB_ADMIN) return;
    setLoading(true)
    fetchData2().then(res => {
      setDelegatesLength(res[0].data?.data?.length)
      setAttendesLength(res[1].data?.data?.length)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      toast(err?.response?.data?.message).error()
    })
  }, [])

  return (
    <>
      <Skeleton visible={loading}>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-[7.5]">
          {user?.role === UserRoles.ADMIN ?
            <>
              <AppCard title="Total Events" totalNumber={eventsLength} action="View Events" actionPath="/dashboard/events" />
              <AppCard title="Total Delegates" totalNumber={delegatesLength} action="View Delegates" actionPath="/dashboard/delegates" />
              <AppCard title="Total Exhibitors" totalNumber={exhibitorsLength} action="View Exhibitors" actionPath="/dashboard/sub-admins" />
              <AppCard title="Total Attendees"
                totalNumber={attendeesLength}
                action="View Attendees" actionPath="/dashboard/attendees" />
            </> :
            <>
              <AppCard title="Total Attendees"
                totalNumber={attendeesLength}
                action="View Attendees" actionPath="/dashboard/attendees" />
              <AppCard title="Total Delegates" totalNumber={delegatesLength} action="View Delegates" actionPath="/dashboard/delegates" />
            </>
          }
        </div>
      </Skeleton>
      {user?.role === UserRoles.ADMIN && <AdminDashboard />}
      {user?.role === UserRoles.SUB_ADMIN && <ViewEvents />}
    </>
  );
}

export default Dashboard;