/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, TextInput } from "@mantine/core";
import useButton from "../../../hooks/useButton";
import { inputStyles, toast } from "../../../utils/helpers";
import { Action_Type, useUserContext } from "../../../context/UserContext";
import { useEffect, useState } from "react";
import { updateUserProfle } from "../../../services/userService";

const Profile = () => {

    const { AppButton, setLoading } = useButton()
    const [info, setInfo] = useState({ name: "", email: "" })
    const { user, userDispatch } = useUserContext()
    useEffect(() => {
        if (user?.name && user?.email) {
            setInfo({
                name: user?.name,
                email: user?.email
            })
        }
    }, [user?.name])

    const handleInputChange = ({ target: { name, value } }: { target: { name: string, value: string } }) => setInfo({ ...info, [name]: value })


    const handleUpdate = (e: any) => {
        e.preventDefault()
        setLoading(true)
        updateUserProfle(info).then(res => {
            setLoading(false)
            toast(res.data.message).success()
            if (userDispatch)
                userDispatch({
                    type: Action_Type.USER_PROFILE,
                    payload: res.data.data,
                })
        }).catch(err => {
            console.log(err)
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }

    return (
        <Flex className="w-full md:w-[60%] flex flex-col md:mx-auto lg:mx-0 justify-around mt-[30px]">
            <form onSubmit={handleUpdate}>
                <TextInput
                    value={info.name}
                    name="name"
                    required
                    className="font-1s w-full"
                    size="md"
                    placeholder="Name"
                    mt={10}
                    sx={{ input: inputStyles }}
                    onChange={handleInputChange}
                />
                <TextInput
                    value={info.email}
                    name="email"
                    required
                    className="font-Poppins w-full"
                    size="md"
                    placeholder="Email"
                    mt={10}
                    sx={{ input: inputStyles }}
                    onChange={handleInputChange}
                />
                <AppButton title="Update" />
            </form>
        </Flex>
    );
}

export default Profile;