/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Navbar, UnstyledButton, Group, Text, ScrollArea, Image } from '@mantine/core';
import { CiSettings } from "react-icons/ci"
import { AiOutlineClose, AiOutlineLogout, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { Action_Type, useUserContext } from '../../context/UserContext';
import { FaUsersRectangle } from "react-icons/fa6"
import { BsFillClipboard2CheckFill } from "react-icons/bs"
import { RxDashboard } from "react-icons/rx"
//@ts-ignore
import logo from "../../images/logo.png";
import { UserRoles } from '../../utils/types';


const MainLink: React.FC<any> = ({ icon, label, link }) => {
    const { userDispatch } = useUserContext()
    const navigate = useNavigate()

    const logout = () => {
        if (userDispatch)
            userDispatch({
                type: Action_Type.LOGOUT_USER,
            })
        navigate("/login")
    }
    return (
        <UnstyledButton
            onClick={() => {
                if (link === "#") {
                    logout()
                } else {
                    navigate(link)
                }
            }}
            sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: 15,
                borderRadius: 10,

                '&:hover': {
                    backgroundColor: 'black'
                },
            })}
        >
            <Group>
                {icon}
                <Text size="sm" className='text-white font-3'>{label}</Text>
            </Group>
        </UnstyledButton>
    );
}

const Sidebar: React.FC<any> = ({ opened, setOpened }) => {
    const { user } = useUserContext()

    const adminSidebarOption = [
        {
            icon: <RxDashboard className='text-white' size={25} />,
            label: "Dashboard",
            link: "/dashboard"
        }
        ,
        {
            icon: <AiOutlinePlus className='text-white' size={25} />,
            label: "Create Event",
            link: "/dashboard"
        },
        {
            icon: <FaUsersRectangle className='text-white' size={25} />,
            label: "Events",
            link: "/dashboard/events"
        },
        {
            icon: <CiSettings className='text-white' size={25} />,
            label: 'Settings',
            link: "/dashboard/settings"
        },
        {
            icon: <AiOutlineLogout size={25} className='text-white' />,
            label: 'Logout',
            link: "#"
        },
    ]

    const subAdminOption = [
        {
            icon: <RxDashboard className='text-white' size={25} />,
            label: "Dashboard",
            link: "/dashboard"
        },
        {
            icon: <FaUsersRectangle className='text-white' size={25} />,
            label: "Events",
            link: "/dashboard"
        },
        {
            icon: <CiSettings className='text-white' size={25} />,
            label: 'Settings',
            link: "/dashboard/settings"
        },
        {
            icon: <AiOutlineLogout size={25} className='text-white' />,
            label: 'Logout',
            link: "#"
        },
    ]

    const sidebarOption = () => {
        if (user?.role === UserRoles.ADMIN) return adminSidebarOption;
        if (user?.role === UserRoles.SUB_ADMIN) return subAdminOption;
        else return subAdminOption;
    }




    return (
        <Navbar p="sm"
            hiddenBreakpoint="sm"
            className='bg-color-1'
            hidden={!opened} width={{ sm: 200, lg: 250 }}
        >
            <ScrollArea className='min-h-[90vh]'>

                <AiOutlineClose
                    onClick={() => setOpened(!opened)}
                    className='block md:hidden ml-2 cursor-pointer'
                    size={30} color='white' />
                <Navbar.Section className='mt-[10px]'>
                    <Group position='left'
                        className='ml-2'
                    >
                        <Image src={logo} width={150} />
                    </Group>
                </Navbar.Section>

                <Navbar.Section
                    grow
                    mb={30}
                    className='mt-[70px]'
                >
                    {sidebarOption().map((item, index) => (
                        <MainLink icon={item.icon} label={item.label} key={index}
                            link={item.link}
                        />
                    ))}
                </Navbar.Section>

            </ScrollArea>
        </Navbar>

    );
};

export default Sidebar;
