import { notifications } from "@mantine/notifications";

const kingchattoken = "kingschattoken"

export const inputStyles = {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    // ':focus': {
    //     border: "1px solid #da1812"
    // }
}

export const toast = (message: string, title?: string) => {
    return {
        success: () => notifications.show({
            color: "green",
            title: title ? title : 'Success',
            message,
            withBorder: true,
            // autoClose: 5000
        }),
        error: () => notifications.show({
            color: "red",
            title: title ? title : 'Failed',
            message: message ?? "Something went wrong!",
            withBorder: true,
        })
    }
}

// export const FRONTEND_URL = "https://delegatecapturepro.pw"

// export const imageUrl = "https://delegatecapturepro.pw/img/profile.png"

export const storeData = (data: string, key?: string) => localStorage.setItem(key || kingchattoken, data)
export const retrieveData = (key?: string) => localStorage.getItem(key || kingchattoken)
export const delData = (key?: string) => localStorage.removeItem(key || kingchattoken)
export const FRONTEND_URL="https://data-capturepro.xyz"

export const imageUrl = "https://data-capturepro.xyz/img/profile.png"
