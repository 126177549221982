export enum UserRoles {
    ADMIN="ADMIN",
    SUB_ADMIN="SUB_ADMIN",
    USER="USER"
}

export type User = {
    name:string;
    email:string;
    country:string;
}