import { List } from "@mantine/core";
import useButton from "../../hooks/useButton"
import { User } from "../../utils/types";

interface UploadedUserProp {
    name:string;
    email:string;
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    users: User[]
}

const UploadedUser:React.FC<UploadedUserProp> = ({ name, email,setUsers,users}) => {
    const { AppButton } = useButton()
    const handleDelete = () => {
        setUsers(users.filter(user=>user.email!==email))
    }
    return (
        <div className='flex flex-row items-center justify-between border-b-[.7px]'>
            <List.Item>{email}
            </List.Item>
            <div className='w-[100px] mb-1'>
                <AppButton title={'Delete'} handleClick={handleDelete} />
            </div>
        </div>
    )

}

export default UploadedUser