import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserContextProvider from './context/UserContext';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);
root.render(
    <UserContextProvider>
        <App />
    </UserContextProvider>
);
