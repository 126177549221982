
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useUserContext, userToken } from '../../context/UserContext';

const PublicRoutes = ({ children }: { children: any }) => {
    const token = userToken()

    const { search } = useLocation()
    
    const redirectUrl = new URLSearchParams(search).get("redirect");

    const {user} = useUserContext()

    if (token && user?.role) {
        return <Navigate to={redirectUrl ? redirectUrl : "/dashboard"} />
    }
    return children
}

export default PublicRoutes;