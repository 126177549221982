import { Button, Card, Flex, Loader, ScrollArea, Skeleton, Table, Text, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { AiOutlineSearch, AiOutlineCopy } from "react-icons/ai";
//@ts-ignore
import _debounce from 'lodash.debounce';
import { Link, useNavigate } from "react-router-dom";
import { CiExport } from "react-icons/ci";
import usePagination from "../../../hooks/usePagination";
import useCSVExport from "../../../hooks/useCSVExport";
import { deleteEvent, getEvents, inviteUsers } from "../../../services/userService";
import { toast } from "../../../utils/helpers";
import DelegatesInviteModal from "../DelegatesInviteModal";
import { User, UserRoles } from "../../../utils/types";
import useButton from "../../../hooks/useButton";
import { useDisclosure } from "@mantine/hooks";
import { useUserContext } from "../../../context/UserContext";
import { GoTrash } from "react-icons/go"
import useConfirmationModal from "../../../hooks/useConfirmationModal";

interface RowProps {

}

const Row = ({ evt, setEvents, events }: any) => {
    const { AppButton } = useButton()
    const { AppButton: AppButton2, setLoading: setLoading2 } = useButton()
    const navigate = useNavigate()
    const [opened, { open, close }] = useDisclosure(false);
    const [uploadedUsers, setUploadedUsers] = useState<User[]>([])
    const { user } = useUserContext()
    const [loading, setLoading] = useState(false)


    const handleDeleteEvent = () => {
        setLoading(true)
        deleteEvent(evt?._id).then(res => {
            setLoading(false)
            setEvents(events.filter((item: any) => item?._id !== evt?._id))
            toast(res.data?.message).success()
        }).catch(err => {
            setLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }

    const { open: openConfirmationModal, ConfirmationModal } = useConfirmationModal(handleDeleteEvent)

    const handleInviteUsers = () => {
        setLoading2(true)
        inviteUsers(evt?._id, uploadedUsers, true, true).then(res => {
            toast(res.data?.message).success()
            setLoading2(false)
        }).catch(err => {
            toast(err?.response?.data?.message).error()
            setLoading2(false)
        })
    }

    function copyToClipboard(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(function () {
                    toast('Link copied successfully!').success()
                    console.log('Text copied to clipboard: ' + text);
                })
                .catch(function (err) {
                    console.error('Failed to copy text: ', err);
                });
        } else {
            console.error('Clipboard API is not supported in this browser.');
        }
    }

    return (
        <>
            <tr key={evt?._id}
            >
                <td
                    className="cursor-pointer"
                    onClick={() => navigate(`/dashboard/event-attendance/${evt?._id}`)}>{evt?.name}</td>
                <td>{new Date(evt?.date).toLocaleTimeString('en-GB',
                    { day: '2-digit', month: "2-digit", year: "numeric" }).split(",")[0].trim()
                }</td>
                <td><Link to={`/dashboard/event-delegates/${evt?._id}`}>Delegates</Link></td>
                <td>
                    <Link to={`/dashboard/event-attendance/${evt?._id}`}>Attendances</Link>
                </td>
                <td>
                    <Button
                        onClick={() => copyToClipboard(`https://data-capturepro.xyz/events/${evt._id}`)}
                        className="hover:bg-[#f8f9fa] bg-[#f8f9fa] text-black hover:text-black"
                        leftIcon={<AiOutlineCopy size={20} />}>Copy Link</Button>
                </td>
                <td>
                    <div className="w-[150px]">
                        <AppButton title="Invite Delegates" handleClick={open} />
                    </div>
                </td>


                {uploadedUsers?.length > 0 && <td>
                    <AppButton2 title="Send Invite" handleClick={handleInviteUsers} styles="bg-color-1" />
                </td>}

                {user?.role === UserRoles.ADMIN &&
                    <>
                        {uploadedUsers.length === 0 && <td></td>}
                        <td>{
                            loading ?
                                <Loader color="red" /> :
                                <GoTrash className="text-color-3 cursor-pointer" size={20}
                                    onClick={openConfirmationModal}
                                />}</td>
                    </>

                }
            </tr>
            <DelegatesInviteModal
                opened={opened}
                close={close}
                setUsers={setUploadedUsers}
                users={uploadedUsers}
            />
            <ConfirmationModal
                modalTitle={`Are you sure you want to delete - ${evt?.name}`}
            />
        </>
    )
}

const ViewEvents = () => {
    const [events, setEvents] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [entry, setEntry] = useState("")
    const { user } = useUserContext()

    useEffect(() => {
        setIsLoading(true)
        getEvents().then(res => {
            setEvents(res.data.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            toast(err?.response?.data?.message).error()
        })
    }, [])


    const { data, PaginationBtn } = usePagination(entry ? searchResults : events)
    const excelHeader = [
        { label: "Event ID", key: "_id" },
        { label: "Event Name", key: "name" },
        { label: "Time", key: "date" },
    ]
    const { CSVBtn } = useCSVExport(entry ? searchResults : events, excelHeader)

    const handleSearch = _debounce(async (query: string) => {
        if (!query) return;
        try {
            const response = await getEvents(query)
            if (response?.data?.data.length > 0) {
                setSearchResults(response.data?.data)
            }
            if (response?.data?.data.length === 0) {
                setSearchResults([])
            }
            if (response?.data?.data.length === 0) {
                setSearchResults([])
            }
        } catch (error: any) {
            console.log(JSON.stringify(error.response, null, 5))
        }
    }, 300)
    return (
        <>
            <Flex className="flex flex-row items-center justify-between">
                <h1 className="my-1 font-3">Events</h1>
                <Button
                    className="bg-color-1"
                    leftIcon={<CiExport size={15} />}>
                    <CSVBtn />
                </Button>
            </Flex>
            <Card
                mih={500}
                padding={20}>
                <Skeleton visible={loading}>
                    <ScrollArea>
                        <TextInput
                            onChange={({ target }) => {
                                setEntry(target.value)
                                handleSearch(target.value)
                            }}
                            icon={<AiOutlineSearch />}
                            placeholder="Search event" className="h-[70px]" />
                        <Table verticalSpacing={'md'}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th></th>
                                    <th></th>
                                    <th>Link</th>
                                    <th>Invite Users</th>
                                    <th></th>
                                    {user?.role === UserRoles.ADMIN && <th>Delete</th>}
                                </tr>
                            </thead>
                            <tbody>{data()?.map(evt =>
                                <Row evt={evt}
                                    events={events}
                                    setEvents={setEvents} />)}</tbody>
                        </Table>
                        {events.length === 0 &&
                            <Flex className="items-center justify-center mt-5">
                                <Text>No data found</Text>
                            </Flex>
                        }
                    </ScrollArea>
                </Skeleton>

                <PaginationBtn />
            </Card>
        </>
    );
}

export default ViewEvents;